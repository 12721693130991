import React, { Component } from 'react';

class Jumbotron extends Component {
    render() {
        return (
            <div className="jumbotron">
                <section className="py-2 text-center container">
                    <div className="row py-lg-4">
                        <div className="col-lg-8 col-md-12 mx-auto">
                            <h1 className="font-weight-light"> {this.props.title} </h1>
                            <p> {this.props.subtitle} </p>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Jumbotron;