import React, {Component} from 'react';

class ProjectNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeClass: '',
        };
        this.scrollFunction = this.scrollFunction.bind(this)
    }

    scrollFunction () {
        let activeClass = 'normal';
        if(window.scrollY >= 20){
            activeClass = 'nav-scroll';
        }
        this.setState({ activeClass });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.scrollFunction);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollFunction);
    }

    render() {
        return (
            <nav className={`navbar navbar-expand-sm navbar-light sticky-top p-0 ${this.state.activeClass}`}>
                <div className="container-fluid">
                    <a className="navbar-brand" href="/">mehul.</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="/" title="work">work</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/experiments" title="side projects">side projects</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/about" title="about">about</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/resume" title="resume">resume</a>
                            </li>
                            <li className="nav-item">
                                <a className={`nav-link ${this.props.next ? '' : 'disabled'}`} href={this.props.next} title="next ->">
                                    next 
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default ProjectNav