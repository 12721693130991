import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, {useEffect, useState} from 'react';
import { Layout } from '../layout/Layout';
import Footer from '../components/Footer/Footer';
import ProjectNav from '../components/ProjectNav/ProjectNav';
import Jumbotron from '../components/Jumbotron/Jumbotron';

export default ({ data, pageContext }) => {
    const { frontmatter, body } = data.mdx;
    const { previous, next } = pageContext;

    const [signedIn, setsignedIn] = useState(false);
    const [passwordValue, setpasswordValue] = useState('');
    const [passwordValid, setpasswordValid] = useState('');

    useEffect(() => {
      const gettingSecretValue = window.localStorage.getItem('secret');
      if (gettingSecretValue && frontmatter.passwordRequired) {
        checkPassword (gettingSecretValue)
      };
    }, [frontmatter.passwordRequired])

    function unlockFunction () {
      checkPassword(passwordValue);
    }

    function checkPassword (pValue) {
      if(pValue === 'connect20') {
        setpasswordValid('is-valid');
        setsignedIn(true);
        window.localStorage.setItem('secret', pValue);
        return true
      } else {
        setpasswordValid('is-invalid')
        return false
      }
    }

    function handleChange (event) {
      setpasswordValue(event.target.value);
    }

    return (
      <Layout>
        <ProjectNav next={next ? next.fields.slug : null}/>

        {signedIn || !frontmatter.passwordRequired
          ? (
              <div>
                <Jumbotron title={frontmatter.title} subtitle={frontmatter.description} />
                <div className="container">
                  <div className="row justify-content-md-center">
                    <MDXRenderer>{body}</MDXRenderer>
                  </div>
                </div>
                
                <div className="text-center mt-5">
                  {previous && (
                    <Link to={previous.fields.slug}>
                      <p>Previous: {previous.frontmatter.title}</p>
                    </Link>
                  )}
                  {next && (
                    <Link to={next.fields.slug}>
                      <p>Next: {next.frontmatter.title}</p>
                    </Link>
                  )}
                </div>
                <Footer />
              </div>
            )
          : (
              <div style={{backgroundColor: '#f4f8fd'}} >
                <div className="container d-flex align-content-center flex-wrap" width="100%" style={{height: `calc(100vh - 64px)`}}>
                    <div className="row d-flex justify-content-center">
                        <div className="col col-md-5 align-self-center">
                            <div className="input-group">
                                
                                <input 
                                  type="password" 
                                  className={`form-control ${passwordValid}`} 
                                  placeholder="Enter password" 
                                  aria-label="Enter password" 
                                  aria-describedby="button-addon2" 
                                  value={passwordValue} onChange={handleChange}
                                  onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                      unlockFunction()
                                    }
                                  }}
                                />
                                <button 
                                  className="btn btn-primary" 
                                  type="button" id="button-addon2" 
                                  onClick={unlockFunction}>
                                    Unlock
                                </button>
                                
                                <div className="invalid-feedback"> Please provide a valid password. </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
            )
        } 

      </Layout>
    );
};

export const query = graphql`
  query PostsBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        description
        passwordRequired
      }
    }
  }
`;